<template>
    <div class="Decline">
        <div v-if="!showSuccessMessage && !showErrorMessage">

        </div>
        <div class="booking-completed-container"><br>
            <div class="booking-completed-header">Ups... Der skette en fejl.</div>
            <div class="booking-completed-message">
                 <p>
                En fejl opstod under jeres betaling. 
                Jeres booking og betaling blev ikke foretaget og vi opfordrer til at gentage bookingen.
              </p>
              <p>
                Hvis fejlen opstår igen, er I meget velkommen til at kontakte os på <a href="mailto: kontakt@reklameskaerm.dk">kontakt@reklameskaerm.dk</a><br> eller give os et kald på +45 93 60 9001.
              </p>
            </div>
      </div>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'Decline',
}
</script>

<style scoped>
.booking-completed-container {
  width: 50%;
  margin: 0 auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.booking-completed-container div {
  text-align: center;
}

.booking-completed-header {
  color: #fd4261;
  font-weight: 600;
  font-size: 26px;
}
</style>
